import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyBPvt0OmfCLOr21fH5MIGgIXVe1r5kTj8E",
    authDomain: "bookshelf-app-78283.firebaseapp.com",
    projectId: "bookshelf-app-78283",
    storageBucket: "bookshelf-app-78283.appspot.com",
    messagingSenderId: "887677602416",
    appId: "1:887677602416:web:995f47a86722ae601142b5"
  };

  initializeApp(firebaseConfig);

  const db=getFirestore(); //Veri tabanının referansını alıyoruz.

  const auth=getAuth(); //Kullanıcının referansını alıyoruz.

  export{db,auth};
  